import React, { useState, useEffect } from "react"
import styled from 'styled-components'

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({theme}) => theme.spacing['2xs']};
    color: ${({theme}) => theme.colors.gray.dark};

    label {
        display: flex;
        align-items: center;
        gap: 1.375rem;
        padding: ${({theme}) => theme.spacing.xs}rem 0;

        input[type=checkbox] {
          display: none;
        }
    }

    .ref-input {
      display: inline-block;
      position: relative;
      height: 1.25rem;
      width: 1.25rem;
      border-radius: ${({theme}) => theme.spacing['3xs']};
      border: 0.0625rem solid ${({theme}) => theme.colors.gray.dark};
      background-color: transparent;
    }

    label input:checked + .ref-input:after {
      content: " ";
      border: solid white;
      border-width: 0 ${({theme}) => theme.spacing['4xs']} ${({theme}) => theme.spacing['4xs']} 0;
      transform: rotate(40deg);
      width: ${({theme}) => theme.spacing['2xs']};
      height: 15px;
      position: absolute;
      top: 0px;
      left: 0.3125rem;
      display: block;
    }

    label input:checked ~ .ref-input {
      border-color: ${({theme}) => theme.colors.secondary.health_green};
      background-color: ${({theme}) => theme.colors.secondary.health_green};
    }

    .input-checkbox {
      display: none !important;
    }
`

export default function InputCheckbox({options, setValue, currentValues, required, error=false }){
  const [disabledIndex, setDisabledIndex] = useState(null);

  useEffect(() => {
    const inputs = document.querySelectorAll('.ref-input');
    
    if(error){
      inputs.forEach(input => {
        input.style.borderColor = "#BC1C1C"
      })
    }else{
      inputs.forEach(input => {
        input.style.borderColor = "#515151"
      })
    }      
  }, [error])

  function handleClick(e){
    if(!e.target.value) return
    const answer = {key: e.target.id, value: e.target.value}
    if(answer.value === "Não possuo deficiência"){
      if(disabledIndex){
        setDisabledIndex(null)
      } else {
        setDisabledIndex(answer.key);
        currentValues = []
      }
    }
    if(currentValues.some(value => value.key === answer.key)){
      setValue(currentValues.filter(value => value.key !== answer.key))
    } else {
      setValue([...currentValues, answer])
    }
  }

    return (
      <InputWrapper>
        {options.map(option => {
          return (
            <label
            onClick={handleClick}
            >
              <input
                className="input-checkbox"
                type="checkbox"
                id={option.key}
                value={option.value}
                checked={currentValues.some(value => value.key === option.key)} 
                readOnly
                required={required}
                key={option.key}
                disabled={disabledIndex !== null && disabledIndex !== option.key}
              />
              <div className="ref-input"></div>
              {option.value}
            </label>
          )
        })}
      </InputWrapper>
    )
 
}