import React from "react";

function AvatarsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="18"
      fill="none"
      viewBox="0 0 27 18"
    >
      <path
        fill="#018762"
        d="M2.128 17.92h12.365c.784 0 1.334-.119 1.652-.357.318-.239.477-.591.477-1.058 0-.503-.124-1.034-.37-1.593a6.128 6.128 0 00-1.083-1.627 8.193 8.193 0 00-1.728-1.428 9.075 9.075 0 00-2.308-1.015c-.862-.252-1.801-.377-2.818-.377-1.022 0-1.963.125-2.823.377-.86.251-1.628.59-2.307 1.015a8.066 8.066 0 00-1.728 1.428 6.253 6.253 0 00-1.083 1.627c-.25.56-.374 1.09-.374 1.593 0 .467.159.82.477 1.058.318.238.868.358 1.651.358zm6.187-9.21c.722 0 1.384-.196 1.987-.588a4.309 4.309 0 001.445-1.585c.361-.665.542-1.412.542-2.243a4.434 4.434 0 00-1.182-3.051A3.988 3.988 0 009.844.327 3.626 3.626 0 008.314 0c-.533 0-1.04.11-1.521.33-.48.221-.904.53-1.27.924a4.402 4.402 0 00-1.167 3.063c0 .82.179 1.562.537 2.223a4.299 4.299 0 001.438 1.582 3.55 3.55 0 001.984.588z"
        opacity="0.8"
      ></path>
      <path
        fill="#018762"
        d="M25.206 18c.65 0 1.112-.136 1.384-.408.273-.272.409-.66.409-1.162 0-.667-.17-1.35-.512-2.047-.34-.697-.828-1.337-1.464-1.92-.636-.582-1.399-1.054-2.289-1.415-.89-.362-1.881-.543-2.975-.543-.895 0-1.7.12-2.414.362a7.98 7.98 0 00-1.88.9c.564.457 1.049.97 1.453 1.539.404.57.71 1.148.915 1.735.206.588.303 1.139.29 1.654-.013.516-.154.95-.423 1.305h7.506zm-5.454-9.003c.63 0 1.208-.17 1.731-.508a3.702 3.702 0 001.255-1.373c.313-.577.47-1.225.47-1.943 0-.698-.158-1.33-.474-1.897a3.733 3.733 0 00-1.258-1.354 3.129 3.129 0 00-1.724-.504c-.62 0-1.193.165-1.716.496-.524.331-.945.78-1.263 1.347a3.825 3.825 0 00-.477 1.904c0 .718.158 1.367.473 1.947.316.58.735 1.039 1.259 1.377a3.112 3.112 0 001.724.508z"
        opacity="0.2"
      ></path>
    </svg>
  );
}

export default AvatarsIcon;