import React from "react"
import styled from 'styled-components'
import { useEffect } from "react"

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({theme}) => theme.spacing['2xs']};
    color: ${({theme}) => theme.colors.gray.dark};

    label {
        display: flex;
        align-items: center;
        gap: 1.375rem;
        padding: ${({theme}) => theme.spacing.xs}rem 0;

        input[type=radio] {
          display: none;
        }
    }

    .ref-input-radio {
      display: inline-block;
      position: relative;
      height: 1.25rem;
      width: 1.25rem;
      border-radius: 50%;
      border: 0.0625rem solid ${({theme}) => theme.colors.gray.dark};
      background-color: transparent;
    }

    label input:checked + .ref-input-radio:after {
      content: " ";
      border-radius: 50%;
      width: ${({theme}) => theme.spacing['2xs']};
      height: ${({theme}) => theme.spacing['2xs']};
      position: absolute;
      top: 0.3125rem;
      left: 0.3125rem;
      display: block;
      background: #FFF;
    }

    label input:checked ~ .ref-input-radio {
      border-color: ${({theme}) => theme.colors.secondary.health_green};
      background-color: ${({theme}) => theme.colors.secondary.health_green};
    }
`

export default function InputRadio({options, setValue, currentValue, required, error=false}){
    useEffect(() => {
      const inputs = document.querySelectorAll('.ref-input-radio');
      
      if(error){
        inputs.forEach(input => {
          input.style.borderColor = "#BC1C1C"
        })
      }else{
        inputs.forEach(input => {
          input.style.borderColor = "#515151"
        })
      }      
    }, [error])

    return (
      <InputWrapper>
        {options.map(option => {
          return (
            <label
              onClick={e => {
                setValue({key: e.target.id, value: option.value})
              }}
            >
              <input
                className="input-radio"
                type="radio"
                id={option.key}
                value={option.value}
                checked={option.key === currentValue.key}
                readOnly
                required={required}
                key={option.key}
              />
              <div className="ref-input-radio"></div>
              {option.value}
            </label>
          )
        })}
      </InputWrapper>
    )
}