import React, { useEffect } from "react"
import Header from '../../components/profissionaisSaude/Header'
import Footer from '../../components/profissionaisSaude/Footer'
import { useState } from "react"
import { Stepper } from "../../components/profissionaisSaude/stepper"
import styled from 'styled-components'
import { diversityData } from "../../utils"
import InputRadio from "../../components/profissionaisSaude/Inputs/inputRadio"
import InputCheckbox from "../../components/profissionaisSaude/Inputs/inputCheckbox"
import { TextInput } from "../../components/profissionaisSaude/Inputs/InputText"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import AvatarsIcon from "../../assets/icons/avatars"
import { navigate } from "gatsby"
import colors from "../../styles/tokens/colors"

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  section {
    display: flex;
    flex-direction: column;
    margin: ${({theme}) => theme.spacing['2xl']} 0 ${({theme}) => theme.spacing.layout2l} 0;

    form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 69.375rem;
      max-width: 80vw;
      margin: ${({theme}) => theme.spacing.l} auto 0 auto;

      main {
        padding: 0 ${({theme}) => theme.spacing['2xs']};
        display: flex;
        flex-direction: column;
        gap: ${({theme}) => theme.spacing.s};
        margin-top: ${({theme}) => theme.spacing.s};

        .text-error {
          color: ${({theme}) => theme.colors.auxiliary.alert};
          font-size: 0.8125rem;
        }
      }

      .buttons {
        width: 100%;
        margin: ${({theme}) => theme.spacing.l} 0;

        display: flex;
        justify-content: space-between;

        a {
          max-width: 10.375rem;
        }
      }

      .privacyInfo {
        width: 100%;
        max-width: fit-content;
        display: flex;
        gap: ${({theme}) => theme.spacing.s};
        align-items: center;
        background-color: #0187620d;
        padding: 0.5625rem ${({theme}) => theme.spacing.s};
        color: ${({theme}) => theme.colors.gray.dark};
        border-radius: ${({theme}) => theme.spacing['3xs']};

        span{
          font-size: 0.8125rem;
        }
      }
    }
  }
`

const OtherInputs = styled.div `
  .inputs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${({theme}) => theme.spacing.m};
    margin-top: ${({theme}) => theme.spacing['2xs']};

    > div {
      > div:nth-child(2) {
        border: 1px solid ${colors.blue[300]};
        box-shadow: 0px 3px 5px #0072EC55;
      }
    }
  }

  .label-other-inputs {
    color: ${({theme}) => theme.colors.gray.text_base};
    font-size: ${({theme}) => theme.fontSize.fontSize.fontSizeBase};
    font-weight: ${({theme}) => theme.fontWeight.fontWeight.fontWeightMedium};
  }
` 

export default function Cadastro() {
  const [error, setError] = useState(false)
  const [step, setStep] = useState(0)
  const [value, setValue] = useState("")
  const [isOtherCheck, setIsOtherCheck] = useState(false)
  
  const answerModel = {}
  const keys = []
  const steps = diversityData.map(question => {
    question.type === "check"
      ? (answerModel[question.key] = [])
      : (answerModel[question.key] = "")
    keys.push(question.key)
    return question.title
  })
  const [answer, setAnswer] = useState(answerModel)

  const currentQuestion = diversityData[step]
  const currentKey = keys[step]
  const currentAnswer = answer[currentKey]
  const isInitialStep = step === 0;

  function handleSubmit(e) {
    e.preventDefault()

    if (!currentAnswer || currentAnswer.length === 0) {
      setError(true)
      return
    } else {
      setError(false)
    }

    if (step < diversityData.length - 1) {
      setStep(step + 1)
      return
    }
    //Aqui deverá ser inserido o código para inserir dados no banco de dados
    navigate('../cadastro-diversidade-resumo',{state: {answers: answer}})
  }

  function backStep() {
    if(!isInitialStep){
      setError(false)
      setStep(step - 1)
    }
  }

  useEffect(() => {
    if (!value) return
    setAnswer({ ...answer, [currentKey]: value })
    setValue("")
  }, [value, answer, currentKey])

  useEffect(() => {
    if(currentQuestion.type === "check"){
      if(currentAnswer.filter((el) => {return el.value === 'Outra' }).length >= 1){
        setIsOtherCheck(true)
      }else{
        setIsOtherCheck(false)
      }
    }else{
      setIsOtherCheck(false)
    }
  }, [currentQuestion, currentAnswer])

  useEffect(() => {
      if (!value) return
      setAnswer({ ...answer, [currentKey]: value })
      setValue("")

      if (!currentAnswer) {
        setError(true)
      } else {
        setError(false)
      }
    }, [value])

  return (
    <Container>
      <Header />
      <section>
        <Stepper steps={steps} currentStepNumber={step + 1} />
        <form onSubmit={handleSubmit}>
          <h2>{step <= diversityData.length - 1 && currentQuestion.question}</h2>
          <main>
            {currentQuestion.type === "radio" ? (
              <InputRadio
                currentValue={currentAnswer}
                options={currentQuestion.options}
                setValue={setValue}
                required
                error={error ? true : false}
              />
            ) : (
              <InputCheckbox
                currentValues={currentAnswer}
                options={currentQuestion.options}
                setValue={setValue}
                required
                error={error ? true : false}
              />
            )}
            {(
              currentAnswer.value === 'Outro' || 
              currentAnswer.value === 'Outra' ||
              isOtherCheck
              )
              &&
              <OtherInputs>
                <span className="label-other-inputs">{currentQuestion.labelOtherInputs}</span>
                <div className="inputs">
                  {currentQuestion.otherInputs.map(item => (
                    <TextInput 
                      placeholder={item.placeholder}
                      example={item.example ? item.example : ""}
                      width="350px"
                    />
                  ))}
                </div>
              </OtherInputs>
            }
            {error && <span className="text-error">Por favor, selecione uma das opções disponíveis.</span>}
          </main>
          <div className="buttons">
            <Button
              variant="outlined"
              title={isInitialStep ? "Sair" : "Voltar"}
              link={isInitialStep ? "/saude/painel-inicial" : "#"}
              onClick={backStep}
            />
            <Button
              title={step === diversityData.length - 1 ? "Concluir" : "Próximo"}
              type="submit"
            />
          </div>
          <div className="privacyInfo">
            <AvatarsIcon />
            <span>
              Os dados informados serão de conhecimento da pessoa usuária.
            </span>
          </div>
        </form>
      </section>
      <Footer />
    </Container>
  )
}